span.twitter-typeahead .tt-dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  font-size: 14px;
  text-align: left;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip: padding-box;
  text-shadow: none;
}
span.twitter-typeahead .tt-suggestion > p {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857143;
  color: #333333;
  white-space: nowrap;
  margin:0;
  cursor: pointer;
}
span.twitter-typeahead .tt-suggestion > p:hover,
span.twitter-typeahead .tt-suggestion > p:focus {
  color: #ffffff;
  text-decoration: none;
  outline: 0;
  background-color: #305b80;
}

span.twitter-typeahead {
  width: 100%;
}
.input-group span.twitter-typeahead {
  display: block !important;
}
.input-group span.twitter-typeahead .tt-dropdown-menu {
  top: 32px !important;
}
.input-group.input-group-lg span.twitter-typeahead .tt-dropdown-menu {
  top: 44px !important;
}
.input-group.input-group-sm span.twitter-typeahead .tt-dropdown-menu {
  top: 28px !important;
}